import { AppSettings } from "../constants/appsettings";

const getAuth = () =>{
    return JSON.parse(localStorage.getItem("auth") || '{}');    
};  

const getToken = () =>{
    return localStorage.getItem(AppSettings.API_TOKEN_KEY);
};

const checkPermissionResource = ():boolean => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return (auth.roles === 'admin' 
            || auth.roles === 'aprovControl'
            ) ?true:false;
};

const canCreateOrder = ():boolean => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return (auth.roles === 'admin' 
            || auth.roles === 'adminObra'
            || auth.roles === 'gerenteObra'
            || auth.roles === 'coordObra'
            || auth.roles === 'aprovControl'
            || auth.roles === 'supervAdmin'
            ) ?true:false;
};

const canEditOrder = (idStatus:number):boolean => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return  (
                (auth?.status_profile?.find(
                    (item:any)=>item.idStatus===idStatus
            )
            ?.acessoEdicao)
    )                   
};

const canViewOrder = (idStatus:number):boolean => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return  (
                (auth?.status_profile?.find(
                    (item:any)=>item.idStatus===idStatus
            )
            ?.acessoLeitura)
    )                   
};

const listPerfisObrasRestrita = ['adminObra','gerenteObra','coordObra'];

// const listAliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

const listObrasUsuario = () => {
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    const obras = [];

    obras[0] = -1;
    let allObras = obras.concat(auth.grpobras);
    allObras = allObras.concat(auth.admobra);
    allObras = allObras.concat(auth.goobra);

    return allObras;
};


export{
    getAuth,
    getToken,
    checkPermissionResource,
    canCreateOrder,
    canEditOrder,
    canViewOrder,
    listPerfisObrasRestrita,
    // listAliasPerfilAdmin,
    listObrasUsuario,
}
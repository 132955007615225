import { Edit, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";

export const GrupoObraEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm();

    const gruposDeObrasData = queryResult?.data?.data;

    const { autocompleteProps: usuariosAutocompleteProps } = useAutocomplete({
        resource: "usuarios",
        defaultValue: gruposDeObrasData?.idUsuarioResponsavel,
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("id", {
                        required: translate("validations.requiredField"),
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("pages.gruposobras.fields.id")}
                    name="id"
                    disabled
                />
                <TextField
                    {...register("nomeGrupoObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.nomeGrupoObra}
                    helperText={(errors as any)?.nomeGrupoObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.gruposobras.fields.nomeGrupoObra")}
                    name="nomeGrupoObra"
                />
                <TextField
                    {...register("descGrupoObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.descGrupoObra}
                    helperText={(errors as any)?.descGrupoObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.gruposobras.fields.descGrupoObra")}
                    name="descGrupoObra"
                />
                <Controller
                    control={control}
                    name="idUsuarioResponsavel"
                    rules={{ required: translate("validations.requiredField") }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...usuariosAutocompleteProps}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            getOptionLabel={(item) => {
                                if(typeof(item) === 'object'){
                                    return(
                                        item.id + ' - ' +
                                        usuariosAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.id?.toString(),
                                        )?.nomeUsuario ?? ""
                                    );
                                }else{
                                    return(
                                        item + ' - ' +
                                       usuariosAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.toString(),
                                        )?.nomeUsuario ?? ""
                                    );
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?.id?.toString() === value?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate("pages.gruposobras.fields.idUsuarioResponsavel")}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.idUsuarioResponsavel}
                                    helperText={
                                        (errors as any)?.idUsuarioResponsavel?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                />     
            </Box>
        </Edit>
    );
};

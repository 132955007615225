import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete,InputAdornment } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import React from "react";

export const UsuarioCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        control,
        formState: { errors },
    } = useForm();

    const { autocompleteProps: perfilAutocompleteProps } = useAutocomplete({
        resource: "perfis",
    });

    const { autocompleteProps: deptoAutocompleteProps } = useAutocomplete({
        resource: "departamentos",
    });

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<SVGElement>) => {
      event.preventDefault();
    };

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("nomeUsuario", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.nomeUsuario}
                    helperText={(errors as any)?.nomeUsuario?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.usuarios.fields.nomeUsuario")}
                    name="nomeUsuario"
                />
                <TextField
                    {...register("emailUsuario", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.emailUsuario}
                    helperText={(errors as any)?.emailUsuario?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label={translate("pages.usuarios.fields.emailUsuario")}
                    name="emailUsuario"
                />
                <TextField
                    {...register("senhaUsuario", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.senhaUsuario}
                    helperText={(errors as any)?.senhaUsuario?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ 
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {
                                showPassword ? 
                                    <VisibilityOffOutlinedIcon onClick={handleClickShowPassword}/> 
                                    : <VisibilityOutlinedIcon 
                                            aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}/>
                            }
                        </InputAdornment>
                      }}                      
                    type={showPassword ? 'text' : 'password'}
                    label={translate("pages.usuarios.fields.senhaUsuario")}
                    name="senhaUsuario"
                />
                <TextField
                    {...register("numeroTelefoneUsuario", {
                        required: translate("validations.requiredField"),
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.numeroTelefoneUsuario}
                    helperText={(errors as any)?.numeroTelefoneUsuario?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("pages.usuarios.fields.numeroTelefoneUsuario")}
                    name="numeroTelefoneUsuario"
                />


                    <Controller
                        control={control}
                        name="idPerfil"
                        rules={{ required: translate("validations.requiredField") }}
                        // eslint-disable-next-line
                        defaultValue={null as any}
                        render={({ field }) => (
                            <Autocomplete
                                {...perfilAutocompleteProps}
                                {...field}                                
                                onChange={(_, value) => {
                                    field.onChange(value);                          
                                }}                     
                                getOptionLabel={(item) => {
                                    return (
                                        item.id + ' - ' +
                                        perfilAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.id?.toString(),
                                        )?.nomePerfil ?? ""
                                    );
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    value === undefined ||
                                    option?.id?.toString() === value?.id?.toString()
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate("pages.usuarios.fields.idPerfil")}
                                        margin="normal"
                                        variant="outlined"
                                        error={!!(errors as any)?.idPerfil}
                                        helperText={
                                            (errors as any)?.idPerfil?.message
                                        }
                                        required
                                    />
                                )}
                            />
                        )}
                    />

                <Controller
                        control={control}
                        name="idDepartamento"
                        rules={{ required: translate("validations.requiredField") }}
                        // eslint-disable-next-line
                        defaultValue={null as any}
                        render={({ field }) => (
                            <Autocomplete
                                {...deptoAutocompleteProps}
                                {...field}                                
                                onChange={(_, value) => {
                                    field.onChange(value);                          
                                }}                     
                                getOptionLabel={(item) => {
                                    return (
                                        item.id + ' - ' +
                                        deptoAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.id?.toString(),
                                        )?.nomeDepartamento ?? ""
                                    );
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    value === undefined ||
                                    option?.id?.toString() === value?.id?.toString()
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate("pages.usuarios.fields.idDepartamento")}
                                        margin="normal"
                                        variant="outlined"
                                        error={!!(errors as any)?.idDepartamento}
                                        helperText={
                                            (errors as any)?.idDepartamento?.message
                                        }
                                        required
                                    />
                                )}
                            />
                        )}
                    />

            </Box>
        </Create>
    );
};
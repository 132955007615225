import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";

export const ParametroCreate: React.FC<
    IResourceComponentsProps
> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        control,
        formState: { errors },
    } = useForm();

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("aliasParametroSistema", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.aliasParametroSistema}
                    helperText={(errors as any)?.aliasParametroSistema?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.parametros.fields.aliasParametroSistema")}
                    name="aliasParametroSistema"
                />
                <TextField
                    {...register("descParametroSistema", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.descParametroSistema}
                    helperText={(errors as any)?.descParametroSistema?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.parametros.fields.descParametroSistema")}
                    name="descParametroSistema"
                />
                <TextField
                    {...register("valorParametroSistema", {
                        required: translate("validations.requiredField"),
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.valorParametroSistema}
                    helperText={(errors as any)?.valorParametroSistema?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("pages.parametros.fields.valorParametroSistema")}
                    name="valorParametroSistema"
                />
            </Box>
        </Create>
    );
};
import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

export const ParametroList: React.FC<
    IResourceComponentsProps
> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.parametros.fields.id"),
                type: "number",
                minWidth: 50,
            },
            {
                field: "aliasParametroSistema",
                flex: 1,
                headerName: translate(
                    "pages.parametros.fields.aliasParametroSistema",
                ),
                minWidth: 200,
            },
            {
                field: "descParametroSistema",
                flex: 1,
                headerName: translate("pages.parametros.fields.descParametroSistema"),
                minWidth: 200,
                filterable:false,
            },
            {
                field: "valorParametroSistema",
                flex: 1,
                headerName: translate(
                    "pages.parametros.fields.valorParametroSistema",
                ),
                minWidth: 200,
                filterable:false,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                            {/* <DeleteButton hideText recordItemId={row.id} /> */}
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                filterable:false,
            },
        ],
        [translate],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}/>
        </List>
    );
};
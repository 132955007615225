import { Create, useAutocomplete } from "@refinedev/mui";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";

export const GrupoObraCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { formLoading },
        register,
        control,
        formState: { errors },
    } = useForm();

    const { autocompleteProps: usuariosAutocompleteProps } = useAutocomplete({
        resource: "usuarios",
    });

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("nomeGrupoObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.nomeGrupoObra}
                    helperText={(errors as any)?.nomeGrupoObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.gruposobras.fields.nomeGrupoObra")}
                    name="nomeGrupoObra"
                />
                <TextField
                    {...register("descGrupoObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.descGrupoObra}
                    helperText={(errors as any)?.descGrupoObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.gruposobras.fields.descGrupoObra")}
                    name="descGrupoObra"
                />
                <Controller
                    control={control}
                    name="idUsuarioResponsavel"
                    rules={{ required: translate("validations.requiredField") }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...usuariosAutocompleteProps}
                            {...field}                                
                            onChange={(_, value) => {
                                field.onChange(value);                          
                            }}                     
                            getOptionLabel={(item) => {
                                return (
                                    item.id + ' - ' +
                                    usuariosAutocompleteProps?.options?.find(
                                        (p) =>
                                            p?.id?.toString() ===
                                            item?.id?.toString(),
                                    )?.nomeUsuario ?? ""
                                );
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?.id?.toString() === value?.id?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate("pages.gruposobras.fields.idUsuarioResponsavel")}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.idUsuarioResponsavel}
                                    helperText={
                                        (errors as any)?.idUsuarioResponsavel?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                />
            </Box>
        </Create>
    );
};
import {
    DarkTheme as DefaultDarkTheme,
    LightTheme as DefaultLightTheme,
    RefineThemes,
} from "@refinedev/mui";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { bgBG } from '@mui/x-data-grid/locales';

const LightTheme = createTheme({
    ...DefaultLightTheme,
    timeLine: {
        color: {
            warning: "#fff7e6", 
            default: "#e6fffb",
            info: "#e6f7ff",
            error: "#fff1f0",
            success: "#f6ffed",
        },
        dotColor: {
            warning: "#ffa940", // warning
            default: "#36cfc9", // default
            info: "#40a9ff", // info
            error: "#ff4d4f", // error
            success: "#73d13d", // success
        },
    },
},bgBG);

const DarkTheme = createTheme({
    ...DefaultDarkTheme,
    timeLine: {
        color: {
            warning: "#f2a400",
            default: "#00c2a2",
            info: "#0083c2",
            error: "#c60d00",
            success: "#62c400",
        },
        dotColor: {
            warning: "#9f5700",
            default: "#196966",
            info: "#00579f",
            error: "#a60001",
            success: "#386d19",
        },
    },
},bgBG);

/**
 * Theme Customization - Overrinding variables
 */
const overridedLightTheme = createTheme({
    ...RefineThemes.Red,
    palette: {
        ...RefineThemes.Red.palette,
        primary: {
            main: "#de393d",
        },
        secondary: {
            main: "#be2e38",
        }
    },
    timeLine: {
        color: {
            warning: "#fff7e6", 
            default: "#e6fffb",
            info: "#e6f7ff",
            error: "#fff1f0",
            success: "#f6ffed",
        },
        dotColor: {
            warning: "#ffa940", // warning
            default: "#36cfc9", // default
            info: "#40a9ff", // info
            error: "#ff4d4f", // error
            success: "#73d13d", // success
        },
    }, 
    components: {
        MuiChip: {
          styleOverrides: {
            colorSecondary: {
              backgroundColor: '#ff3399',
            },
            outlinedSecondary:{
                backgroundColor: '#ffcce6'
            }
          },
        },
      },          
},bgBG);


const DarkThemeWithResponsiveFontSizes = responsiveFontSizes(DarkTheme);
const LightThemeWithResponsiveFontSizes = responsiveFontSizes(LightTheme);

export { LightThemeWithResponsiveFontSizes, DarkThemeWithResponsiveFontSizes, overridedLightTheme };
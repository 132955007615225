import {
    useShow,
    IResourceComponentsProps,
    useOne,
    useTranslate,
} from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const GrupoObraShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { data: responsavelData, isLoading: responsavelIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioResponsavel || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: autorData, isLoading: autorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioInclusao || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: editorData, isLoading: editorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioAlteracao || "",
        queryOptions: {
            enabled: !!record,
        },
    });    

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.gruposobras.fields.id")}
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.gruposobras.fields.nomeGrupoObra")}
                </Typography>
                <TextField value={record?.nomeGrupoObra} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.gruposobras.fields.descGrupoObra")}
                </Typography>
                <TextField value={record?.descGrupoObra} />

                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.gruposobras.fields.idUsuarioResponsavel")}
                </Typography>    
                {responsavelIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioResponsavel + ' - ' + responsavelData?.data?.nomeUsuario}</>
                )}                
            
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhInclusao")}
                </Typography>
                <DateField value={record?.dhInclusao} format="DD/MM/YYYY hh:mm:ss"/>                
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioInclusao")}
                </Typography>
                {autorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioInclusao + ' - ' + autorData?.data?.nomeUsuario}</>
                )} 
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhAlteracao")}
                </Typography>
                <DateField value={record?.dhAlteracao} format="DD/MM/YYYY hh:mm:ss"/>

                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioAlteracao")}
                </Typography>
                {editorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioAlteracao + ' - ' + editorData?.data?.nomeUsuario}</>
                )} 

            </Stack>
        </Show>
    );
};

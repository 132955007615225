import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
    useOne,
} from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    EmailField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const UsuarioShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { data: departamentoData, isLoading: departamentoIsLoading } = useOne({
        resource: "departamentos",
        id: record?.idDepartamento || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: perfilData, isLoading: perfilIsLoading } = useOne({
        resource: "perfis",
        id: record?.idPerfil || "",
        queryOptions: {
            enabled: !!record,
        },
    });    

    const { data: autorData, isLoading: autorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioInclusao || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: editorData, isLoading: editorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioAlteracao || "",
        queryOptions: {
            enabled: !!record,
        },
    }); 

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.usuarios.fields.id")}
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.usuarios.fields.nomeUsuario")}
                </Typography>
                <TextField value={record?.nomeUsuario} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.usuarios.fields.emailUsuario")}
                </Typography>
                <EmailField value={record?.emailUsuario} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.usuarios.fields.numeroTelefoneUsuario")}
                </Typography>
                <TextField value={record?.numeroTelefoneUsuario ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.usuarios.fields.idPerfil")}
                </Typography>
                {perfilIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idPerfil + ' - ' + perfilData?.data?.nomePerfil}</>
                )}                                                  
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.usuarios.fields.idDepartamento")}
                </Typography>
                {departamentoIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idDepartamento + ' - ' + departamentoData?.data?.nomeDepartamento}</>
                )}       
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhInclusao")}
                </Typography>
                <DateField value={record?.dhInclusao} format="DD/MM/YYYY hh:mm:ss"/>                
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioInclusao")}
                </Typography>
                {autorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioInclusao + ' - ' + autorData?.data?.nomeUsuario}</>
                )} 
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhAlteracao")}
                </Typography>
                <DateField value={record?.dhAlteracao} format="DD/MM/YYYY hh:mm:ss"/>
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioAlteracao")}
                </Typography>
                {editorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioAlteracao + ' - ' + editorData?.data?.nomeUsuario}</>
                )}            
            </Stack>
        </Show>
    );
};
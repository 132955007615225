import { Create } from "@refinedev/mui";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { HttpError,useTranslate } from "@refinedev/core";

import { IItemPedido, Nullable } from "../../interfaces";
import { InputAdornment } from "@mui/material";
import { SetStateAction, useEffect, useRef, useState } from "react";

export const CreateItemPedidoDrawer: React.FC<
    UseModalFormReturnType<IItemPedido, HttpError, Nullable<IItemPedido>>
> = ({
    saveButtonProps,
    modal: { visible, close,title },
    register,
    control,
    formState: { errors }    
}) => {
    const translate = useTranslate();

    const [itemqtd, setItemqtd] = useState<number>(0);
    const [itempreco, setItempreco] = useState<number>(0);
    const [itemprecototal, setItemprecototal] = useState<number>(0);

    const handleChange = (event: { target: { name: any; value: string; }; }) => {
        switch (event.target.name) {
          case "valorItemQuantidade":
            setItemqtd(parseFloat(event.target.value));
            break;
          case "valorPrecoItemUnidade":
            setItempreco(parseFloat(event.target.value));
            break;
        }        
    }
    
    useEffect(() => {
        setItemprecototal(itemqtd*itempreco);
     }, [itemqtd,itempreco]); 

    return (
        <Drawer
            open={visible}
            onClose={close}
            anchor="right"
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
        >
            <Create
                saveButtonProps={saveButtonProps}
                headerProps={{
                    action: (
                        <IconButton
                            onClick={() => close()}
                            sx={{ width: "30px", height: "30px" }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    avatar: null,
                    title:translate("pages.pedidos.itens.titles.create")
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100%" } }}
            >
                <Box
                    component="form"
                    autoComplete="off"
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    {/* <TextField
                        {...register("codigoItemIncorporator", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.codigoItemIncorporator}
                        helperText={(errors as any)?.codigoItemIncorporator?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("pages.pedidos.itens.fields.codigoItemIncorporator")}
                        name="codigoItemIncorporator"
                        //autoFocus
                    />                     */}
                    <TextField
                        {...register("codigoDiagramaOrdem", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.codigoDiagramaOrdem}
                        helperText={(errors as any)?.codigoDiagramaOrdem?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("pages.pedidos.itens.fields.codigoDiagramaOrdem")}
                        name="codigoDiagramaOrdem"
                    /> 
                    <TextField
                        {...register("descItemPedido", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.descItemPedido}
                        helperText={(errors as any)?.descItemPedido?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("pages.pedidos.itens.fields.descItemPedido")}
                        name="descItemPedido"
                    />    
                    <TextField
                        {...register("codigoTributo", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.codigoTributo}
                        helperText={(errors as any)?.codigoTributo?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        style={{
                            width: "220px"
                        }}
                        label={translate("pages.pedidos.itens.fields.codigoTributo")}
                        name="codigoTributo"
                    />                                         
                    <TextField
                        {...register("valorItemQuantidade", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorItemQuantidade}
                        helperText={(errors as any)?.valorItemQuantidade?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px"
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}                 
                        label={translate("pages.pedidos.itens.fields.valorItemQuantidade")}
                        name="valorItemQuantidade"
                        onChange={handleChange}  
                    />
                    <TextField
                        {...register("descItemUnidade", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.descItemUnidade}
                        helperText={(errors as any)?.descItemUnidade?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        style={{
                            width: "100px"
                        }}                                     
                        label={translate("pages.pedidos.itens.fields.descItemUnidade")}
                        name="descItemUnidade"
                    />       
                    <TextField
                        {...register("valorPrecoItemUnidade", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorPrecoItemUnidade}
                        helperText={(errors as any)?.valorPrecoItemUnidade?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px",
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}
                        // startAdornment={<InputAdornment position="start">R$</InputAdornment>}   
                        
                        
                        label={translate("pages.pedidos.itens.fields.valorPrecoItemUnidade")}
                        name="valorPrecoItemUnidade"
                        onChange={handleChange}  
                    />
                    {/* <TextField
                        {...register("valorDescontoItem", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorDescontoItem}
                        helperText={(errors as any)?.valorDescontoItem?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px",
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}                        
                        label={translate("pages.pedidos.itens.fields.valorDescontoItem")}
                        name="valorDescontoItem"
                    />                     */}
                    {/* <TextField
                        {...register("valorItemLiquido", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorItemLiquido}
                        helperText={(errors as any)?.valorItemLiquido?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px",
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}                        
                        label={translate("pages.pedidos.itens.fields.valorItemLiquido")}
                        name="valorItemLiquido"
                    />   */}
                    <TextField
                        {...register("valorItemTotal", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorItemTotal}
                        helperText={(errors as any)?.valorItemTotal?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "220px"
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}                        
                        label={translate("pages.pedidos.itens.fields.valorItemTotal")}
                        name="valorItemTotal"
                        value={itemprecototal}                                                
                        //inputProps={{readOnly:true}}
                    />                      
                </Box>
            </Create>
        </Drawer>
    );
};
import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { IResourceComponentsProps, useMany, useTranslate } from "@refinedev/core";
import { UsuarioCreate } from "../usuarios/create";

export const GrupoObraList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const { data: usuariosData, isLoading: usuariosIsLoading } = useMany({
        resource: "usuarios",
            ids: dataGridProps?.rows?.map((item: any) => item?.idUsuarioResponsavel) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });


    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.gruposobras.fields.id"),
                type: "number",
                minWidth: 50,
            },
            {
                field: "nomeGrupoObra",
                flex: 1,
                headerName: translate("pages.gruposobras.fields.nomeGrupoObra"),
                minWidth: 200,
            },
            {
                field: "descGrupoObra",
                flex: 1,
                headerName: translate("pages.gruposobras.fields.descGrupoObra"),
                minWidth: 200,
            },
             {
                field: "idUsuarioResponsavel",
                flex: 1,
                headerName: translate(
                    "pages.gruposobras.fields.idUsuarioResponsavel",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idUsuarioResponsavel;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return usuariosIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + usuariosData?.data?.find((item) => item.id === value)
                        ?.nomeUsuario
                    );
                },                
            },   
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                            <DeleteButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                filterable:false,
            },
        ],
        [translate, usuariosData?.data, usuariosIsLoading],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}/>
        </List>
    );
};
import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
    useOne,
} from "@refinedev/core";
import {
    Show,
    NumberField,
    DateField,
    TextFieldComponent as TextField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import { canEditOrder } from "../../utils";

export const PedidoShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { data: autorData, isLoading: autorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioInclusao || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: editorData, isLoading: editorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioAlteracao || "",
        queryOptions: {
            enabled: !!record,
        },
    });    

    const { data: grupoObrasData, isLoading: grupoObrasIsLoading } = useOne({
        resource: "gruposobras",
        id: record?.obra.idGrupoObra || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: obraData, isLoading: obraIsLoading } = useOne({
        resource: "obras",
        id: record?.idObra || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    // const { data: deptoData, isLoading: deptoIsLoading } = useOne({
    //     resource: "departamentos",
    //     id: record?.idDepartamento || "",
    //     queryOptions: {
    //         enabled: !!record,
    //     },
    // });

    const { data: statusData, isLoading: statusIsLoading } = useOne({
        resource: "status",
        id: record?.idStatus || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    return (
        <Show 
            isLoading={isLoading}
            canDelete={false}
            canEdit={canEditOrder(parseInt( record?.idStatus || ""))}
        >
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.id")}
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.idGrupoObra")}
                </Typography>
                {grupoObrasIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.obra.idGrupoObra + ' - ' + grupoObrasData?.data?.nomeGrupoObra}</>
                )} 
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.idObra")}
                </Typography>
                {obraIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idObra + ' - ' + obraData?.data?.nomeObra}</>
                )}               
                {/* <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.idDepartamento")}
                </Typography>
                {deptoIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idDepartamento + ' - ' + deptoData?.data?.nomeDepartamento}</>
                )}  */}
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.idStatus")}
                </Typography>
                {statusIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idStatus + ' - ' + statusData?.data?.nomeStatus}</>
                )} 
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.dhPedido")}
                </Typography>
                <DateField value={record?.dhPedido} format="DD/MM/YYYY hh:mm:ss" />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.numeroContrato")}
                </Typography>
                <NumberField value={record?.numeroContrato ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.numeroPedido")}
                </Typography>
                <NumberField value={record?.numeroPedido ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.codigoInternoFornecedor")}
                </Typography>
                <NumberField value={record?.codigoInternoFornecedor ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.nomeFornecedor")}
                </Typography>
                <TextField value={record?.nomeFornecedor} />
                {/* <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.numeroTelefoneFornecedor")}
                </Typography>
                <NumberField value={record?.numeroTelefoneFornecedor ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.nomeContatoFornecedor")}
                </Typography> */}
                {/* <NumberField value={record?.valorMaterial ?? ""} /> */}
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.observPedido")}
                </Typography>
                <TextField value={record?.observPedido} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.dtInicio")}
                </Typography>
                <DateField value={record?.dtInicio} format="DD/MM/YYYY" />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.dtTermino")}
                </Typography>
                <DateField value={record?.dtTermino} format="DD/MM/YYYY" />
                {/* <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.numeroPedidoSuprimentos")}
                </Typography>
                <NumberField value={record?.numeroPedidoSuprimentos ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.dtEmissaoSuprimentos")}
                </Typography>
                <DateField value={record?.dtEmissaoSuprimentos} format="DD/MM/YYYY hh:mm:ss" /> */}
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.valorAtualContrato")}
                </Typography>
                <NumberField value={record?.valorAtualContrato} options={{style: 'currency', currency: 'BRL', }} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.valorAdicional")}
                </Typography>
                <NumberField value={record?.valorAdicional ?? ""} options={{style: 'currency', currency: 'BRL', }} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.valorNovoContrato")}
                </Typography>
                <NumberField value={record?.valorNovoContrato} options={{style: 'currency', currency: 'BRL', }} />
                {/* <Typography variant="body1" fontWeight="bold">
                    {translate("pages.pedidos.fields.numeroContratoIncorporator")}
                </Typography>
                <NumberField value={record?.numeroContratoIncorporator ?? ""} /> */}
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhInclusao")}
                </Typography>
                <DateField value={record?.dhInclusao} format="DD/MM/YYYY hh:mm:ss"/>                
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioInclusao")}
                </Typography>
                {autorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioInclusao + ' - ' + autorData?.data?.nomeUsuario}</>
                )} 
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhAlteracao")}
                </Typography>
                <DateField value={record?.dhAlteracao} format="DD/MM/YYYY hh:mm:ss"/>
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioAlteracao")}
                </Typography>
                {editorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioAlteracao + ' - ' + editorData?.data?.nomeUsuario}</>
                )}   

            </Stack>
        </Show>
    );
};
import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
    useOne,
} from "@refinedev/core";
import {
    Show,
    NumberField,
    TextFieldComponent as TextField,
    DateField
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const ObraShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { data: grupoObrasData, isLoading: grupoObrasIsLoading } = useOne({
        resource: "gruposobras",
        id: record?.idGrupoObra || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: autorData, isLoading: autorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioInclusao || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    const { data: editorData, isLoading: editorIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioAlteracao || "",
        queryOptions: {
            enabled: !!record,
        },
    });    

    const { data: responsavelAdmData, isLoading: responsavelAdmIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioResponsavelAdm || "",
        queryOptions: {
            enabled: !!record,
        },
    });  

    const { data: responsavelGoData, isLoading: responsavelGoIsLoading } = useOne({
        resource: "usuarios",
        id: record?.idUsuarioResponsavelGo || "",
        queryOptions: {
            enabled: !!record,
        },
    });  

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.id")}
                </Typography>
                <NumberField value={record?.id ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.nomeObra")}
                </Typography>
                <TextField value={record?.nomeObra} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.descObra")}
                </Typography>
                <TextField value={record?.descObra} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.numeroInternoObra")}
                </Typography>
                <NumberField value={record?.numeroInternoObra ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.codigoInternoObra")}
                </Typography>
                <TextField value={record?.codigoInternoObra} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.nomeResponsavelObraAdm")}
                </Typography>
                {responsavelAdmIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioResponsavelAdm + ' - ' + responsavelAdmData?.data?.nomeUsuario}</>
                )}
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.nomeResponsavelObraGo")}
                </Typography>
                {responsavelGoIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioResponsavelGo + ' - ' + responsavelGoData?.data?.nomeUsuario}</>
                )}                
                <Typography variant="body1" fontWeight="bold">
                    {translate("pages.obras.fields.idGrupoObra")}
                </Typography>
                {grupoObrasIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idGrupoObra + ' - ' + grupoObrasData?.data?.nomeGrupoObra}</>
                )}                 
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhInclusao")}
                </Typography>
                <DateField value={record?.dhInclusao} format="DD/MM/YYYY hh:mm:ss"/>                
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioInclusao")}
                </Typography>
                {autorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioInclusao + ' - ' + autorData?.data?.nomeUsuario}</>
                )} 
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.dhAlteracao")}
                </Typography>
                <DateField value={record?.dhAlteracao} format="DD/MM/YYYY hh:mm:ss"/>
                <Typography variant="body1" fontWeight="bold">
                    {translate("audit.fields.idUsuarioAlteracao")}
                </Typography>
                {editorIsLoading ? (
                    <>Loading...</>
                ) : (
                    <>{record?.idUsuarioAlteracao + ' - ' + editorData?.data?.nomeUsuario}</>
                )}            
            </Stack>
        </Show>
    );
};
import { useTranslate } from "@refinedev/core";
import { createTheme, useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import type { ChipProps } from "@mui/material/Chip";

type StatusPedidoProps = {
    // status?: "digitado" | "aguardAprovGO" | "aguardAprovGGO" | "aguardAprovSuprim" | "aguardAprovGerSuprim" |
    //             "aguardAprovControla" | "aguardAprovSuperin" | "aguardGerarSap" | "aguardAprovSap" | "aprovadoSap" |
    //             "naoAprovado" | "cancelado";
    status?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;    
};

export const StatusPedido: React.FC<StatusPedidoProps> = ({ status }) => {
    const translate = useTranslate();
    const { palette } = useTheme();

    let color: ChipProps["color"];
    let variant: ChipProps["variant"]

    switch (status) {
        // case "digitado":
        // case "aguardGerarSap":            
        //     color = "info";
        //     break;
        // case "aguardAprovGO":
        // case "aguardAprovGGO":
        // case "aguardAprovSuprim":
        // case "aguardAprovGerSuprim":
        // case "aguardAprovControla":
        // case "aguardAprovSuperin":            
        // case "aguardAprovSap":                        
        //     color = "warning";
        //     break;
        // case "aprovadoSap":
        //     color = "success";
        //     break;
        // case "naoAprovado":
        //     color = "error";
        //     break;
        // case "cancelado":
        //     color = palette.mode === "dark" ? "default" : "secondary";
        //     break;
        case 1:
            variant="filled"
            color = "error";
            break;            
        case 8:            
            variant="outlined"
            color = "warning";
            break;
        case 2:
            variant="outlined"
            color = "success"; //
            break;            
        case 3:
            variant="filled"
            color = "success"; //
            break;

        case 4:
            variant="outlined"
            color = "primary"; //
            break;             
        case 5:
            variant="filled"
            color = "primary"; //
            break;        

        case 6:
        case 7:   
            variant="filled"
            color = "warning"; // precisa ser amarelo
            break;        
        
        case 9:               
            variant="outlined"           
            color = "secondary"; // Rosa
            break;

        case 10:
            variant="filled"
            color = "secondary";
            break;
        case 11:
            variant="filled"
            color = "error";
            break;
        case 12:
            variant="outlined"
            color = palette.mode === "dark" ? "default" : "secondary";
            break;            
    }

    return (
        <Chip
            variant={variant}
            size="small"
            color={color}
            label={translate(`pages.status.id.${status}`)}
        />
    );
};
import React from "react";
import { useRouterContext, useLink, useRouterType } from "@refinedev/core";
import MuiLink from "@mui/material/Link";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import type { RefineLayoutThemedTitleProps } from "@refinedev/mui";

const defaultText = "PlanoPro";

const defaultIcon = (
  <svg 
    viewBox="0 0 481.9 492.8" 
    enableBackground="new 0 0 481.9 492.8" 
    x="0px" 
    y="0px" 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    data-testid="app-logo"
  >
    <rect 
      x="1.1" 
      y="0" 
      fill="#FFFFFF" 
      width="481.9" 
      height="492.8"/>
    <g>
      <path 
        d="M299.7,186.4c-2.9-45.3-51.4-61.3-53.6-62l-18.5-5.9v217.4c-9.7-6.5-19.2-15.5-19.2-33.4   c0-15.2,6.7-25,15.1-32.2v-30.8c-13,7.1-39.3,25.5-41.4,58.7l-0.1,1.1l-0.1,3.1v0.6l0.1,3.9c3,45,51.4,61.1,53.5,61.8l18.6,5.9V157   c9.8,6.5,19.2,15.6,19.2,33.5l0,0c0,15.3-6.7,25.1-15.2,32.3v30.8c13-7.1,39.4-25.5,41.5-58.9l0.1-3.9v-0.4L299.7,186.4z" 
        fill="#DE393D"/>
      <path 
        d="M330.9,164l-8.9-17.3c0,0-8.7,8.6-21.8,21.8c2,5.7,3.2,11.6,3.6,17.6l0.2,4.6l-0.2,4.3   c-0.3,2.9-0.7,5.8-1.4,8.6c6.3-6.2,10.3-10.3,11-11c1.9,9.5,2.3,20.2-4.9,30.8h28.6C346.2,194.6,331.7,165.6,330.9,164" 
        fill="#BE2E38"/>
      <path 
        d="M191.4,189.1l3.1,0.1l-7.1-7.1c11.1-2.2,23.8-2.5,36.1,9v-31c-30.5-12.7-63.1,3.6-64.8,4.5l-17.3,8.9l4.1,4.1   c0,0,7.2,7.2,17.6,17.6c7.5-3.6,15.6-5.7,23.9-6.2H191.4z" 
        fill="#BE2E38"/>
      <path 
        d="M150.8,329l8.9,17.3l4.1-4.1l17.7-17.7c-2.1-5.7-3.4-11.8-3.8-17.9v-4.4l0.2-4.5c0.3-2.8,0.7-5.6,1.4-8.4   c-6.3,6.3-10.3,10.3-10.9,11c-1.9-9.5-2.3-20.2,4.8-30.8h-28.7C135.5,298.5,150,327.5,150.8,329" 
        fill="#BE2E38"/>
      <path 
        d="M290.5,304l-2.8-0.1c3.7,3.7,6.2,6.2,6.7,6.7c-11.1,2.2-23.8,2.5-36.2-9.1v31c30.6,12.8,63.1-3.5,64.9-4.4   l17.3-8.9l-4.1-4.1c0,0-7.1-7.1-17.3-17.3c-7.6,3.6-15.8,5.8-24.1,6.3H290.5z" 
        fill="#BE2E38"/>
      <path 
        d="M191.6,193.2h-0.3l-4.2,0.1c-45.3,2.9-61.3,51.4-62,53.5l-5.9,18.6h69.6c8.1-12.6,19-21.2,27.4-26.5h-58.4   c6.5-9.8,15.6-19.3,33.5-19.3l0,0c15.2,0,24.9,6.6,32.1,15.1c0,0,0.1,0,0.1-0.1v-32.8c-8.5-5-18.1-7.9-28-8.5L191.6,193.2z" 
        fill="#DE393D"/>
      <path 
        d="M357,227.7h-64.2c-8.1,12.6-19.1,21.2-27.4,26.5h58.8c-6.5,9.7-15.5,19.2-33.5,19.2l0,0   c-15.3,0-25-6.7-32.2-15.2l-0.2,0.1V291c8.6,5.1,18.3,8.1,28.3,8.7l3.9,0.2h0.4l4.1-0.2c45.3-2.8,61.3-51.4,62-53.5l5.9-18.6   L357,227.7z" 
        fill="#DE393D"/>
    </g>
  </svg>
);

export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
  wrapperStyles,
  icon = defaultIcon,
  text = defaultText,
}) => {
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  return (
    <MuiLink
      to="/"
      component={ActiveLink}
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        ...wrapperStyles,
      }}
    >
      <SvgIcon height="24px" width="24px" color="primary" style={{fontSize:"3.0rem",marginLeft:"0px"}}>
        {icon}
      </SvgIcon>
      {!collapsed && (
        <Typography
          variant="h6"
          fontWeight={700}
          color="#221f1f"
          fontSize="18px"
          textOverflow="ellipsis"
          overflow="hidden"
          style={{lineHeight:"2",marginLeft:"-5px"}}
        >
          {text}
        </Typography>
      )}
    </MuiLink>
  );
};

import { AuthBindings } from "@refinedev/core";

import {
    AppSettings
} from "./constants";

import axios from "axios";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;



const authProvider: AuthBindings = {
    // ---
    login: async ({ email, password }) => {
        const encodedPassword = Buffer.from(password).toString("base64");
        let user = {};

        try {
            const { data } = await axios.post(`${AppSettings.API_URL}/auth`, {username:email,password:encodedPassword});
            user = data.user;
            localStorage.setItem(AppSettings.API_TOKEN_KEY, data.token);
            localStorage.setItem(AppSettings.API_REFRESH_TOKEN, data.refreshtoken);
        
        }catch (error: any) {
            return {
                success: false,
                error,
            };
        };

        if (user) {
            localStorage.setItem("auth", JSON.stringify(user));            
            return {
                success: true,
                redirectTo: "/",
            };
        }

        return {
            success: false,
            error: {
                message: "Login Error",
                name: "Invalid email or password",
            },
        };
    },
    // ---
    check: async () => {
        const user = localStorage.getItem("auth");

        if (user) {
            return {
                authenticated: true,
            };
        }

        return {
            authenticated: false,
            logout: true,
            redirectTo: "/login",
            error: {
                message: "Check failed",
                name: "Unauthorized",
            },
        };
    },
    // ---    
    logout: async () => {
        localStorage.removeItem(AppSettings.API_TOKEN_KEY);
        localStorage.removeItem("auth");
        return {
            success: true,
            redirectTo: "/login",
        };    
    },
    // ---    
    onError: async (error) => {
        if (error.status === 401 || error.status === 403) {
            return {
                logout: true,
                redirectTo: "/login",
                error,
            };
        }

        return {};
    },
    // ---
    getPermissions: async () => {
        const user = localStorage.getItem("auth");

        if (user) {
            const { roles,rolesid,rolesname,status_profile } = JSON.parse(user);

            return  {
                roles,rolesid,rolesname,status_profile
            };
        }

        return null;
    },
    // ---
    getIdentity: async () => {
        const user = localStorage.getItem("auth");

        if (user) {
            const { id,email, roles, name } = JSON.parse(user);

            return {
                id,
                email,
                roles,
                name: name,
                //avatar: "https://i.pravatar.cc/300",
                avatar: AccountCircleIcon.muiName
            };
        }

        return null;
    },
    // ---
    register:  async ({ email, roles }) => {
        // const user = mockUsers.find((user) => user.email === email);
        // const id = 1;

        // if (user) {
        //     return {
        //         success: false,
        //         error: {
        //             name: "Register Error",
        //             message: "User already exists",
        //         },
        //     };
        // }

        // mockUsers.push({ id, email, roles });

        // return {
        //     success: true,
        //     redirectTo: "/login",
        // };

        return {
            success: false,
            redirectTo: "/login",
            error: {
                name: "Erro ao Criar Nova Conta",
                message: "Funcionalidade indisponível. Por favor entre em contato com o administrador do sistema!",
            },
        };        
    },
    // ---
    forgotPassword: async ({ email }) => {
        // // send password reset link to the user's email address here

        // // if request is successful
        // return {
        //     success: true,
        //     redirectTo: "/login",
        // };

        // // if request is not successful
        // return {
        //     success: false,
        //     error: {
        //         name: "Forgot Password Error",
        //         message: "Email address does not exist",
        //     },
        // };

        return {
            success: false,
            redirectTo: "/login",
            error: {
                name: "Erro ao acessar Esqueci minha senha",
                message: "Funcionalidade indisponível. Por favor entre em contato com o administrador do sistema!",
            },
        };     
    },
    // ---  
    updatePassword: async ({ password }) => {
        // // update the user's password here

        // // if request is successful
        // return {
        //     success: true,
        //     redirectTo: "/login",
        // };

        // // if request is not successful
        // return {
        //     success: false,
        //     error: {
        //         name: "Forgot Password Error",
        //         message: "Email address does not exist",
        //     },
        // };

        return {
            success: false,
            redirectTo: "/login",
            error: {
                name: "Erro ao acessar Mudar minha senha",
                message: "Funcionalidade indisponível. Por favor entre em contato com o administrador do sistema!",
            },
        };           
    },
    // ---            
};

export default authProvider;